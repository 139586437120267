import React, { useEffect } from 'react';
import queryString from 'query-string';
import removeOptinmonsterClasses from 'utils/removeOptinmonsterClasses';
import { HvacCalcForm } from 'components/Tools/HvacCalculator/Form';
import { ROICalcForm } from 'components/Tools/ROICalculator/Form';
import SeerEnergyCalc from 'components/Tools/EnergySavings';
import { HvacDuctForm } from 'components/Tools/HvacDuctCalculator/Form';
import { Helmet } from 'react-helmet';

const EmbeddedFormPage = (props) => {
  const url = typeof window !== 'undefined' ? window.location.search : '';
  const params = queryString.parse(url);

  useEffect(() => {
    document.addEventListener('om.Campaign.load', function (event) {
      if (
        window.location.pathname.includes('tools/embedded-form') ||
        window.location.pathname.includes('roi-calculator-results')
      ) {
        const optinCampaign = document.querySelector(`#om-${event.detail.Campaign.id}`);
        optinCampaign.parentNode.removeChild(optinCampaign);
        removeOptinmonsterClasses();
      }
    });
  }, []);

  const renderForm = () => {
    switch (params?.form_type) {
      case 'roi-calculator':
        return <ROICalcForm isEmbed />;
      case 'hvac-load-calculator':
        return <HvacCalcForm isEmbed />;
      case 'seer-energy-savings-calculator':
        return <SeerEnergyCalc isEmbed />;
      case 'hvac-duct-calculator':
        return <HvacDuctForm isEmbed />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={props.location.href} />
      </Helmet>
      {renderForm()}
    </>
  );
};

export default EmbeddedFormPage;
