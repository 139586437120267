/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Col, Row } from 'components/Core/Grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RadioGroup from 'components/Core/RadioGroup';
import Select from 'components/Core/Select';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import NumberInputField from 'components/Core/NumberInputField';
import Button from 'components/Core/Button';
import GatedSubscribeForm from 'components/Tools/GatedSubscribeForm';
import EmbedForm from 'components/Tools/EmbeddableForm';
import EmbedLink from 'components/Tools/EmbedLink';
import useHvacClimateMap from 'hooks/useHvacClimateMap';
import { getFormValid } from 'utils/formUtils';
import { HvacFormContainer } from './styles';
import { FormContainer } from '../../styles';

import {
  ClimateRegion,
  InsulationGrade,
  SunExposure,
  AmountOfWindows,
  AirTightness,
  Occupants,
  ExtraDeviceWattage,
} from '../utils/content';

const HvacForm = ({ width, height, gatedForm, ...otherProps }) => {
  const pageImages = useHvacClimateMap();

  const mapImage =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'HVAC Climate Map')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  const nameType = {
    mod_zone: 'Where is the house located?',
    mod_insulation: 'How would you rate the insulation?',
    mod_sunny: 'How much sunlight does it get?',
    mod_windows: 'How many windows does it have?',
    mod_tightness: 'Are the doors and windows well sealed?',
    mod_occupants: 'How many people live there?',
    mod_devices_quantity: 'Extra Device Heat Wattage',
    mod_area: 'What is the square footage? (SQFT)',
    mod_height_percent: 'What is the ceiling height? (FT)',
    mod_glassroom: 'Glassed-in room',
    mod_kitchen: 'Kitchen',
  };

  const formik = useFormik({
    initialValues: {
      mod_area: 100,
      mod_height_percent: 8,
    },
    validationSchema: Yup.object().shape({
      mod_zone: Yup.string().required(),
      mod_insulation: Yup.string().required(),
      mod_sunny: Yup.string().required(),
      mod_windows: Yup.string().required(),
      mod_tightness: Yup.string().required(),
      mod_occupants: Yup.string().required(),
      mod_devices_quantity: Yup.string().required(),
      mod_area: Yup.string().required(),
      mod_height_percent: Yup.string().required(),
      mod_glassroom: Yup.string().required(),
      mod_kitchen: Yup.string().required(),
    }),
    onSubmit: (values) => {
      // eslint-disable-next-line no-use-before-define
      calcAll(values);
    },
  });

  const [totalBtu, setTotalBtu] = useState(0);
  const [snapBtu, setSnapBtu] = useState(0);
  const [snapTon, setSnapTon] = useState(0);
  const [gateFormCookieIncrease, setGateFormCookieIncrease] = useState(0);
  const [isRecalc, setRecalc] = useState(true);

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(
      id,
      id === 'mod_glassroom' || id === 'mod_kitchen' ? Number(newValue) : newValue,
    );
    setRecalc(true);
  };

  const snapTo = (btu) => {
    const counts = [9000, 12000, 18000, 24000, 30000, 36000, 48000, 60000];
    const goal = btu;

    let start;
    let end;
    if (goal <= counts[0]) {
      end = counts[0];
      return {
        btu_snap: `${end.toLocaleString()}`,
        ton_snap: `${(end / 12000.0).toLocaleString()}`,
      };
    }

    if (goal > counts[counts.length - 1]) {
      start = counts[counts.length - 1];
      return {
        btu_snap: `${start.toLocaleString()}`,
        ton_snap: `${(start / 12000.0).toLocaleString()}`,
      };
    }

    for (var i in counts) {
      if (counts[i] >= goal) {
        start = counts[i - 1];
        end = counts[i];
        break;
      }
    }

    return {
      btu_snap: `${start.toLocaleString()}-${end.toLocaleString()}`,
      ton_snap: `${(start / 12000.0).toLocaleString()}-${(end / 12000.0).toLocaleString()}`,
    };
  };

  const calcAll = (values) => {
    const {
      mod_zone,
      mod_insulation,
      mod_sunny,
      mod_windows,
      mod_tightness,
      mod_occupants,
      mod_devices_quantity,
      mod_area,
      mod_height_percent,
      mod_glassroom,
      mod_kitchen,
    } = values;

    const coolingbtusqft = 26;
    let mod_height = 0;
    if (mod_height_percent > 8) {
      mod_height = (mod_height_percent - 8) * 0.06;
    }
    let mod_devices = 0;
    if (mod_devices_quantity > 0) {
      mod_devices = (mod_devices_quantity / 100) * 340;
    }

    const base_btu = mod_area * coolingbtusqft;
    const mod_btu =
      mod_zone +
      mod_height +
      mod_insulation +
      mod_sunny +
      mod_windows +
      mod_tightness +
      mod_glassroom;
    const total_btu = base_btu + base_btu * mod_btu + mod_occupants + mod_kitchen + mod_devices;
    const btu_100 = Math.ceil(total_btu / 100) * 100;
    const { btu_snap, ton_snap } = snapTo(btu_100);
    setTotalBtu(btu_100);
    setSnapBtu(btu_snap);
    setSnapTon(ton_snap);
    dataLayer.push({
      event: 'calculator',
      calculator_type: 'HVAC Calculator',
      climate_region: mod_zone,
      insulation_grade: mod_insulation,
      sun_exposure: mod_sunny,
      amount_of_windows: mod_windows,
      air_tightness: mod_tightness,
      number_of_occupants: mod_occupants,
      device_heat_wattage: mod_devices_quantity,
      space_area: mod_area,
      space_height: mod_height_percent,
      glass_room: mod_glassroom,
      kitchen: mod_kitchen,
      btu: btu_snap,
      calculated_cooling_load: btu_100.toLocaleString(),
    });

    setGateFormCookieIncrease(gateFormCookieIncrease + 1);
    setRecalc(false);
  };

  const formValid = getFormValid({ ...formik });

  return (
    <FormContainer isEmbed={otherProps.isEmbed} {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <HvacFormContainer width={width} height={height} {...otherProps}>
            <Row>
              <Col lg={12} md={12} sm={24} xs={24}>
                <div className="hvac-form--form" data-position="hvac form">
                  <FormLabelWithField
                    formControlProps={{ className: 'hvac-form--form-item' }}
                    label={nameType.mod_zone}
                    isRequired
                    helperText="Please select climate region"
                  >
                    <Select
                      id="mod_zone"
                      nameType={nameType.mod_zone}
                      formValid={formValid}
                      values={formik.values}
                      placeholder="Select climate region"
                      options={ClimateRegion}
                      handleChange={onHandleChange}
                    />
                  </FormLabelWithField>

                  <NumberInputField
                    formControlProps={{ className: 'hvac-form--form-item' }}
                    id="mod_area"
                    label={nameType.mod_area}
                    min={100}
                    max={99999}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />

                  <NumberInputField
                    formControlProps={{ className: 'hvac-form--form-item' }}
                    id="mod_height_percent"
                    label={nameType.mod_height_percent}
                    min={8}
                    max={100}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />

                  <FormLabelWithField
                    formControlProps={{ className: 'hvac-form--form-item' }}
                    label={nameType.mod_insulation}
                    isRequired
                    helperText="Please select insulation grade"
                  >
                    <Select
                      id="mod_insulation"
                      nameType={nameType.mod_insulation}
                      formValid={formValid}
                      values={formik.values}
                      placeholder="Select insulation grade"
                      options={InsulationGrade}
                      handleChange={onHandleChange}
                    />
                  </FormLabelWithField>

                  <FormLabelWithField
                    formControlProps={{ className: 'hvac-form--form-item' }}
                    label={nameType.mod_sunny}
                    isRequired
                    helperText="Please select sun exposure"
                  >
                    <Select
                      id="mod_sunny"
                      nameType={nameType.mod_sunny}
                      formValid={formValid}
                      values={formik.values}
                      placeholder="Select sun exposure"
                      options={SunExposure}
                      handleChange={onHandleChange}
                    />
                  </FormLabelWithField>

                  <FormLabelWithField
                    formControlProps={{ className: 'hvac-form--form-item' }}
                    label={nameType.mod_windows}
                    isRequired
                    helperText="Please select amount of windows"
                  >
                    <Select
                      id="mod_windows"
                      nameType={nameType.mod_windows}
                      formValid={formValid}
                      values={formik.values}
                      placeholder="Select amount of windows"
                      options={AmountOfWindows}
                      handleChange={onHandleChange}
                    />
                  </FormLabelWithField>

                  <FormLabelWithField
                    formControlProps={{ className: 'hvac-form--form-item' }}
                    label={nameType.mod_tightness}
                    isRequired
                    helperText="Please select windows & doors air tightness"
                  >
                    <Select
                      id="mod_tightness"
                      nameType={nameType.mod_tightness}
                      formValid={formValid}
                      values={formik.values}
                      placeholder="Select windows & doors air tightness"
                      options={AirTightness}
                      handleChange={onHandleChange}
                    />
                  </FormLabelWithField>

                  <FormLabelWithField
                    formControlProps={{ className: 'hvac-form--form-item' }}
                    label="Does the house have these rooms?"
                  >
                    <div style={{ marginBottom: '15px' }}>
                      <RadioGroup
                        id="mod_glassroom"
                        nameType={nameType.mod_glassroom}
                        options={[
                          { value: 0.4, label: 'Yes' },
                          { value: 0.0, label: 'No' },
                        ]}
                        values={formik.values}
                        formValid={formValid}
                        handleChange={onHandleChange}
                      />
                    </div>
                    <div>
                      <RadioGroup
                        id="mod_kitchen"
                        nameType={nameType.mod_kitchen}
                        options={[
                          { value: 4000, label: 'Yes' },
                          { value: 0, label: 'No' },
                        ]}
                        values={formik.values}
                        formValid={formValid}
                        handleChange={onHandleChange}
                      />
                    </div>
                  </FormLabelWithField>

                  <FormLabelWithField
                    formControlProps={{ className: 'hvac-form--form-item' }}
                    label={nameType.mod_occupants}
                    isRequired
                    helperText="Please select occupants"
                  >
                    <Select
                      id="mod_occupants"
                      nameType={nameType.mod_occupants}
                      formValid={formValid}
                      values={formik.values}
                      placeholder="Select occupants"
                      options={Occupants}
                      handleChange={onHandleChange}
                    />
                  </FormLabelWithField>

                  <FormLabelWithField
                    formControlProps={{ className: 'hvac-form--form-item' }}
                    label="Extra Device Heat Wattage (W)"
                    isRequired
                    helperText="Please select extra device heat wattage (W)"
                  >
                    <Select
                      id="mod_devices_quantity"
                      nameType={nameType.mod_devices_quantity}
                      formValid={formValid}
                      values={formik.values}
                      placeholder="Select extra device heat wattage (W)"
                      options={ExtraDeviceWattage}
                      handleChange={onHandleChange}
                    />
                  </FormLabelWithField>
                </div>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24} className="sticky-container">
                <div className="hvac-form-right-container">
                  <img src={mapImage} className="hvac-form-map-image" />
                  <p className="hvac-form-map-description">RECOMMENDED EQUIPMENT CAPACITY</p>
                  <GatedSubscribeForm gatedForm={gatedForm} increaseCookie={gateFormCookieIncrease}>
                    <div className="hvac-form-right-gate-container">
                      <div className="hvac-form-total-calculation">
                        <p className="hvac-form-total-btu-range">
                          {snapBtu} BTU / {snapTon} Tons
                        </p>
                        <p className="hvac-form-total-btu-total">
                          Calculated Cooling Load:{' '}
                          <font>
                            {totalBtu.toLocaleString()} BTU /{' '}
                            {(totalBtu / 12000.0).toLocaleString()} Tons
                          </font>
                        </p>
                      </div>

                      <Button
                        dataPosition="Form - Calculate CTA"
                        className="hvac-form-calc-button"
                        onClick={() => formik.submitForm()}
                        disabled={!isRecalc}
                      >
                        Calculate
                      </Button>
                    </div>
                  </GatedSubscribeForm>
                  <EmbedLink
                    show={!otherProps.isEmbed}
                    openCopyEmbedLinkDialog={otherProps?.openCopyEmbedLinkDialog}
                  />
                  {snapBtu !== 0 && (
                    <p className="hvac-form--disclaimer-text">
                      This Load Calculation Tool provides estimates in good faith for general
                      information purposes only. There are many factors which may impact or falsify
                      the results of this calculation and the accuracy of the results are not
                      guaranteed. This load calculation tool utilizes the square foot method to
                      generate an approximation, and is based on a 72F degree indoor, and 95F degree
                      outdoor temperatures. For precise measurements, a full Manual J assessment
                      should be performed by a licenced design engineer.
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </HvacFormContainer>
        </div>
      </div>
    </FormContainer>
  );
};

export const HvacCalcForm = EmbedForm(HvacForm);
