export const ClimateRegion = [
  {
    label: 'Region #1 - Orange',
    value: 0.1,
  },
  {
    label: 'Region #2 - Green',
    value: 0.05,
  },
  {
    label: 'Region #3 - Dark Blue',
    value: 0.0,
  },
  {
    label: 'Region #4 - Light Blue',
    value: -0.05,
  },
  {
    label: 'Region #5 - Turqoise',
    value: -0.1,
  },
];

export const InsulationGrade = [
  {
    label: 'Excellent',
    value: -0.1,
  },
  {
    label: 'Better than average',
    value: -0.05,
  },
  {
    label: 'Average',
    value: 0.0,
  },
  {
    label: 'Worse than average',
    value: 0.05,
  },
  {
    label: 'Bad',
    value: 0.1,
  },
];

export const SunExposure = [
  {
    label: 'A large amount',
    value: 0.08,
  },
  {
    label: 'A medium amount',
    value: 0.0,
  },
  {
    label: 'Little or none',
    value: -0.08,
  },
];

export const AmountOfWindows = [
  {
    label: 'Standard Amount',
    value: 0.0,
  },
  {
    label: 'Many (more than standard)',
    value: 0.1,
  },
];

export const AirTightness = [
  {
    label: 'Sealed tight and double-paned',
    value: -0.08,
  },
  {
    label: 'Typical fit and seal',
    value: 0.0,
  },
  {
    label: 'Not well-sealed and single-paned',
    value: 0.08,
  },
];

export const Occupants = [
  {
    label: '2 or less',
    value: 0,
  },
  {
    label: '3',
    value: 600,
  },
  {
    label: '4',
    value: 1200,
  },
  {
    label: '5',
    value: 1800,
  },
  {
    label: '6',
    value: 2400,
  },
];

export const ExtraDeviceWattage = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '1',
    value: 600,
  },
  {
    label: '2',
    value: 1200,
  },
  {
    label: '3',
    value: 1800,
  },
  {
    label: '4',
    value: 2400,
  },
  {
    label: '5',
    value: 3000,
  },
  {
    label: '6',
    value: 3600,
  },
];
