import styled from 'styled-components';

export const HvacFormContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 50px 28px 40px;
  background: var(--white);
  box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.1);
  .hvac-form--description {
    max-width: 810px;
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-150);
    text-align: right;
    color: var(--grey-8);
    margin: 0 auto 24px;
  }

  .hvac-form--form {
    max-width: 810px;
    margin: 0 auto;

    .hvac-form--form-item {
      margin-bottom: 39px;
      .MuiFormControl-root {
        margin-bottom: 0px;
      }
    }
  }
  .radio-div input {
    width: 0;
  }
  .sticky-container {
    height: 1400px;
    @media (max-width: 767px) {
      height: 1200px;
    }
    @media (max-width: 479px) {
      height: auto;
    }
  }
  .hvac-form-right-container {
    margin-left: 30px;
    padding: 50px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--grey-1);
    position: sticky;
    top: ${(props) => (props.type === 'embedded' ? '0' : '7rem')};
    .p {
      width: 350px;
    }

    .hvac-form-right-gate-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .hvac-form--subscribe-form {
      display: block;
      margin-bottom: 50px;
      margin-left: auto;
      margin-right: auto;
      input {
        width: 270px;
        height: 55px !important;
      }
      button {
        background-color: var(--titan-blue-3);
        font-size: var(--font-size-20);
        line-height: var(--line-height-110);
        text-align: center;
        letter-spacing: var(--letter-spacing--0-01);
        color: var(--white);
        font-weight: var(--font-weight-300);
        width: 270px;
        margin-left: 0;
      }
    }

    .hvac-form-map-image {
      width: 100%;
    }
    .hvac-form-map-description {
      margin-top: 40px;
      margin-bottom: 10px;
    }
    .hvac-form-total-calculation {
      text-align: center;
      .hvac-form-total-btu-range {
        font-size: var(--font-size-20);
        font-weight: var(--font-weight-700);
      }
      .hvac-form-total-btu-total {
        font {
          color: var(--critical-3);
          display: block;
        }
      }
    }
    .hvac-form--disclaimer-text {
      font-size: var(--font-size-13);
      font-style: italic;
      margin: 15px 0px 0px 0px;
    }
    @media (max-width: 575px) {
      padding: 20px;
      margin-left: 0px;
      .hvac-form-map-description {
        text-align: center;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 24px;

    .hvac-form--form {
      max-width: 552px;

      .hvac-form--form-item {
        margin-bottom: 24px;
      }

      .hvac-form--submit-button {
        width: 100%;
      }
    }
  }

  @media (max-width: 575px) {
    padding: 20px 24px;
  }
`;
